import React, {useEffect, useState} from "react";
import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";
import {useToast} from "../../contexts/ToastContext";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {useLanguage} from "../../contexts/LanguageContext";
import {X} from "lucide-react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../shared/select";
import {LocaleData, transformJsonToLocaleDataList} from "../../lib/transform-json";
import {Input} from "../shared/Input";
import {Checkbox} from "../shared/checkbox";

export const ExportAccountsModal = () => {
    const {isOpen, onClose, type, data} = useModal();

    const isModalOpen = isOpen && type === "export-accounts";

    const {add} = useToast();
    const {translate} = useLanguage();

    const [locales, setLocales] = useState<LocaleData[]>([]);

    const [form, setForm] = useState({
        parse_pattern: '',
        count: 0,
        encode: true,
        locale: {
            id: -1,
            country: '',
            language: '',
        }
    });

    const axiosPrivate = useAxiosPrivate();

    const typeParse = ['login', 'password', 'recovery_codes', 'recovery_email', 'recovery_password', 'recovery_password_imap', 'phone'];

    const [loading, setLoading] = useState<boolean>(false);

    const [error, setError] = useState<Record<string, { value: string }>>({});
    const [errorResponse, setErrorResponse] = useState<string | null>(null);

    const handleAddPattern = (item: string) => {
        if (form.parse_pattern.includes(item + ':')) {
            setForm(prev => ({...prev, parse_pattern: prev.parse_pattern.replace(item + ':', '')}));
        } else {
            setForm(prev => ({...prev, parse_pattern: prev.parse_pattern + item + ':'}));
        }
    }

    const clearPattern = () => {
        setForm(prev => ({...prev, parse_pattern: ''}));
    }

    const handlerSelectLocale = (value: string) => {
        const locale = locales.find(locale => locale.id.toString() === value);
        if (locale) {
            setForm(prev => ({...prev, locale: locale}));
        }
    }

    const handlerAddAccounts = () => {
        setError({});
        setLoading(true);

        let hasError = false;

        if (!form.locale) {
            setError(prev => ({...prev, locale: {value: 'locale_is_required'}}));
            hasError = true;
        }

        if (form.count < 1) {
            setError(prev => ({...prev, count: {value: 'count_must_be_greater_than_zero'}}));
            hasError = true;
        }

        if (form.parse_pattern.length === 0) {
            setError(prev => ({...prev, parse_pattern: {value: 'parse_pattern_is_required'}}));
            hasError = true;
        }

        if (hasError) {
            setLoading(false);
            return;
        }

        axiosPrivate.post('/accounts/export', form)
            .then((response) => {
                if (response.status === 200) {
                    // Создаём Blob из данных ответа
                    const blob = new Blob([response.data], { type: 'text/plain' });

                    // Создаем URL для скачивания файла
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'exported_accounts.txt'; // Название файла, который будет скачиваться
                    link.click(); // Программно вызываем клик для скачивания
                    clearPattern();
                    onClose();
                }
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    useEffect(() => {
        if (isModalOpen) {
            axiosPrivate.get('/locales/list').then((response) => {
                if (response.status === 200 && response.data.data) {
                    const localesData = transformJsonToLocaleDataList(response.data.data)
                        .filter(locale => locale.type === 'STANDARD')
                        .sort((a, b) => a.name.localeCompare(b.name));

                    if (localesData.length === 0) {
                        add({type: "error", message: "No locale found"});
                        onClose();
                    }

                    setLocales(localesData);
                    setForm(prev => ({...prev, locale: localesData[0]}));
                }
            }).catch((error) => {
                add({type: "error", message: "Error while fetching locales"});
                console.log(error);
                onClose();
            });

            return () => {
            }
        }
    }, [isModalOpen]);

    const handleClose = () => {
        onClose();
    }

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {translate('accounts.list.modal.export-title')}
                    </DialogTitle>
                </DialogHeader>

                <div className={"flex flex-col p-2 gap-2"}>

                    <span className="text-xs font-bold text-gray/50">Select locale accounts</span>
                    <Select
                        disabled={loading}
                        required
                        value={form.locale?.id?.toString() || ''}
                        onValueChange={(value) => handlerSelectLocale(value)}
                    >

                        <SelectTrigger
                            className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none`}>
                            <SelectValue placeholder="Locale Select"/>
                        </SelectTrigger>

                        <SelectContent className={"flex flex-col"}>
                            <div className={"h-fit max-h-60 overflow-scroll"}>
                                {locales.map((locale) => (
                                    <SelectItem
                                        key={locale.id.toString()}
                                        value={locale.id.toString()}
                                        className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs"
                                    >
                                        <div className={"flex flex-row gap-2"}>
                                            <img
                                                className={"h-4 w-6 rounded-md overflow-hidden"}
                                                alt={locale.country.toUpperCase()}
                                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${locale.country.toUpperCase()}.svg`}/>
                                            <div className={"flex flex-col items-start justify-center"}>
                                                            <span
                                                                className={"max-w-[200px] whitespace-nowrap truncate"}>{locale.name}</span>
                                            </div>
                                        </div>
                                    </SelectItem>
                                ))}
                            </div>
                        </SelectContent>
                    </Select>

                    <span
                        className={"text-xs font-bold text-gray/50"}>{translate('accounts.list.modal.select-structure-line')}</span>
                    <div className={"grid grid-cols-3 gap-1"}>
                        {typeParse.map((item, index) => (
                            <div
                                key={index}
                                onClick={() => handleAddPattern(item)}
                                className={`flex flex-row p-1 rounded-md ${form.parse_pattern.includes(item + ':') ? 'bg-gray/50' : 'bg-green/10 hover:bg-green/50'} cursor-pointer transition-all items-center justify-between`}
                            >
                                <span
                                    className={"text-xs text-gray-dark dark:text-white"}
                                >
                                    {item}
                                </span>
                            </div>
                        ))}
                    </div>
                    <span
                        className={"text-xs font-bold text-gray/50"}>{translate('accounts.list.modal.parse-pattern')}</span>
                    <div className={"h-8 flex w-full flex-row p-1 rounded-md bg-gray/50 items-center justify-between overflow-scroll"}>
                        <div className={"flex flex-row w-max-[100px]"}>
                            <p className={"text-xs font-bold text-green/50 whitespace"}>{form.parse_pattern}</p>
                        </div>
                        {form.parse_pattern.length > 0 &&
                            <div className={"flex flex-row p-1 bg-red/10 hover:bg-red/50 rounded-md cursor-pointer"}>
                                <X onClick={clearPattern} size={12} className={"text-gray-dark dark:text-white"}/>
                            </div>
                        }
                    </div>

                    <div className={"grid lg:grid-cols-3 md:grid-cols-1 sm:grid-cols-1 gap-2"}>
                        <div className={"grid items-center justify-center gap-1"}>
                            <span className="text-xs font-bold text-gray/50">Count</span>
                            <Input
                                error={error.count?.value || ''}
                                type={"number"}
                                value={form.count}
                                onChange={(e) => setForm(prev => ({...prev, count: parseInt(e.target.value)}))}
                            />
                        </div>
                        <div className={"grid gap-1"}>
                            <span className="text-xs font-bold text-gray/50">Decode</span>
                            <Checkbox
                                defaultChecked={form.encode}
                                onCheck={(value) => setForm(prev => ({...prev, encode: value}))}/>
                        </div>
                    </div>

                    {errorResponse && <span className={"text-xs text-red-500"}>{errorResponse}</span>}
                </div>

                <DialogFooter className="bg-gray-100 px-6 py-4">
                    <Button onClick={handlerAddAccounts} disabled={loading}>
                        {translate('accounts.list.modal.export')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
