import {KeywordData} from "../../lib/transform-json";
import React from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {ChartArea} from "lucide-react";
import {Separator} from "../../components/shared/separator";
import {useLanguage} from "../../contexts/LanguageContext";

type SelectKeywordsProps = {
    keywords: KeywordData[];
    disable?: boolean;
    selectedKeyword?: string | number;
    onSelectKeyword?: (keyword: string) => void;
    onPutKeywords?: () => void;
}

const SelectKeywords: React.FC<SelectKeywordsProps> = ({keywords, selectedKeyword, onSelectKeyword, onPutKeywords, disable}) => {

    const {translate} = useLanguage();

    return (
        <div className={"w-52"}>
            <Select
                disabled={disable}
                value={selectedKeyword?.toString()}
                onValueChange={(value) => onSelectKeyword && onSelectKeyword(value)}
            >
                <SelectTrigger
                    className={`bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-fit`}>
                    <SelectValue placeholder={translate('keywords.select-keywords')}/>
                </SelectTrigger>

                <SelectContent className={"flex flex-col h-fit overflow-scroll"}>
                    <div className={"flex flex-col gap-1 max-h-144 overflow-scroll"}>
                        {Object.values(keywords).map((k) => (
                            <SelectItem
                                key={k.keyword}
                                value={k.keyword}
                                className="cursor-pointer font-bold hover:bg-green/40 rounded-md text-xs w-full">
                                    <span
                                        className={"flex flex-row lowercase"}>
                                        {k.keyword}
                                    </span>
                            </SelectItem>
                        ))}
                    </div>
                    <Separator orientation={"horizontal"} className={"mt-1"}/>
                    <div
                        onClick={() => onPutKeywords && onPutKeywords()}
                        className={"flex text-xs text-gray-dark dark:text-white rounded-md p-1 hover:bg-green/50 transition-all items-center justify-center cursor-pointer mt-1"}>
                        <span className={"font-bold"}>{translate('keywords.put-keywords')}</span>
                    </div>
                </SelectContent>
            </Select>
        </div>
    )
}

export {SelectKeywords}
