export type ErrorData = {
    error_message?: string;
}

export type UserData = {
    uuid: string;
    username: string;
    email: string;
    name: string;
    surname: string;
    status: string;
    roles: string[];
    imageSrc: string;
}

export type UserDataList = {
    list: UserData[];
    totalCount: number;
}

export const transformJsonToUserDataList = (list: any[]): UserData[] => {
    return list.map(item => transformJsonToUserData(item));
}

export const transformJsonToUserDataListCount = (data: any): UserDataList => {
    return {
        list: transformJsonToUserDataList(data.list),
        totalCount: data.total_count
    };
}

export const transformJsonToUserData = (data: any): UserData => {

    return {
        uuid: data.uuid,
        username: data.username,
        email: data.email,
        name: data.name,
        surname: data.surname,
        status: data.status,
        roles: data.roles,
        imageSrc: data.image_src
    };
}

export type ApiKeyData = {
    api_key: string;
}

export const transformJsonToApiKeyData = (data: any): ApiKeyData => {
    return {
        api_key: data.api_key
    };
}

export type AppData = {
    auid: string;
    app_id: string,
    details: { [key: string]: AppDetailData };
    date_update?: string;
    date_installed?: string;
    status: string;
    locale?: LocaleData | null;
    loading?: boolean;
    rating: AppRatingData;
}

export const transformJsonToAppDataList = (list: any[]): AppData[] => {
    return list.map(item => transformJsonToAppData(item));
}

export const transformJsonToAppData = (data: any): AppData => {
    return {
        auid: data.auid,
        app_id: data.app_id,
        details: data.details,
        rating: data.rating,
        locale: data.locale ? transformJsonToLocaleData(data.locale) : null,
        date_update: data.updated,
        date_installed: data.installed,
        status: data.status,
        loading: false
    };
}

export type AppRatingData = {
    rating: number | 0;
    reviews: string | "0";
    installs: string | "0";
}

export type AppDetailData = {
    name: string;
    category: string;
    content_rating: string;
    publisher: string;
    image_src: string;
}

export type LocaleData = {
    id: number,
    country: string,
    language: string
    name: string,
    type?: string,
}

export const transformJsonToLocaleDataList = (list: any[]): LocaleData[] => {
    return list.map(item => transformJsonToLocaleData(item));
}

export const transformJsonToLocaleData = (data: any): LocaleData => {
    return {
        id: data.id,
        country: data.country,
        language: data.language,
        name: data.name,
        type: data.type,
    };
}

export type KeywordData = {
    id: string
    keyword: string;
    locale: LocaleData;
    favorite: boolean;
    status: string;
    position: number;
    position_diff: number;
}

export type KeywordDataList = {
    list: KeywordData[];
    totalCount: number;
}

export const transformJsonToKeywordData = (data: any): KeywordData => {
    return {
        id: data.kuid,
        keyword: data.keyword,
        favorite: data.favorite,
        status: data.status,
        locale: transformJsonToLocaleData(data.locale),
        position: data.position,
        position_diff: data.position_diff
    };

}

export const transformJsonToKeywordDataList = (list: any[]): KeywordData[] => {
    return list.map(item => transformJsonToKeywordData(item));
}

export const transformJsonToKeywordDataListCount = (data: any): KeywordDataList => {
    return {
        list: transformJsonToKeywordDataList(data.list),
        totalCount: data.total_count
    };
}

export type AccountData = {
    login: string;
    password: string;
    decryptPassword?: boolean;
    recoveryEmail?: string;
    status: string;
    machine?: string;
    locale: LocaleData;
    dateCreate: string;
    dateUpdate: string;
    loading?: boolean;
    installed_apps?: AppData[] | null;
}

export type AccountDataList = {
    list: AccountData[];
    totalCount: number;
}

export const transformJsonToAccountDataList = (list: any[]): AccountData[] => {
    return list.map(item => transformJsonToAccountData(item));
}

export const transformJsonToAccountDataListCount = (data: any): AccountDataList => {
    return {
        list: transformJsonToAccountDataList(data.list),
        totalCount: data.total_count
    };
}

export const transformJsonToAccountData = (data: any): AccountData => {
    return {
        login: data.login,
        password: data.auth_data.password,
        recoveryEmail: data.auth_data.recovery_email,
        status: data.account_status,
        machine: data.name_machine || "NULL",
        locale: transformJsonToLocaleData(data.locale),
        dateCreate: data.created,
        dateUpdate: data.updated,
        installed_apps: data.installed_apps != null ? transformJsonToAppDataList(data.installed_apps) : null
    };
}

export type MachineData = {
    name: string;
    account?: string;
    recoveryLogin?: string;
    status: string;
    androidVersion: string;
    versionAndroid: string;
    dateCreate: string;
    params?: MachineParamsData | null;
    dateUpdate: string;
    loading?: boolean;
    tasks?: WorkingTask[] | null;
}

export type MachineParamsData = {
    serial?: string;
    imei?: string;
    build?: string;
    rpiPath?: string;
    rpiId?: string;
    rpiFolder?: string;
    locale?: string;
    tcpIpPortAdb?: string;
}

export type MachineDataList = {
    list: MachineData[];
    totalCount: number;
}

export const transformJsonToMachineParamsData = (data: any): MachineParamsData => {
    return {
        serial: data.serial,
        imei: data.imei,
        build: data.build,
        rpiPath: data.rpi_path,
        rpiId: data.rpi_id,
        rpiFolder: data.rpi_folder,
        locale : data.locale,
        tcpIpPortAdb: data.tcp_ip_port_adb
    };
}

export const transformJsonToMachineDataList = (list: any[]): MachineData[] => {
    return list.map(item => transformJsonToMachineData(item));
}

export const transformJsonToMachineDataListCount = (data: any): MachineDataList => {
    return {
        list: transformJsonToMachineDataList(data.list),
        totalCount: data.total_count
    };
}

export const transformJsonToMachineData = (data: any): MachineData => {
    return {
        name: data.name_machine,
        account: data.account || 'NULL',
        recoveryLogin: data.recovery_login || 'NULL',
        status: data.status,
        androidVersion: data.type_android,
        versionAndroid: data.version_android,
        params: data.params ? transformJsonToMachineParamsData(data.params) : null,
        dateCreate: data.created,
        dateUpdate: data.updated,
        tasks: data.tasks != null ? transformJsonToWorkingTaskDataList(data.tasks) : null
    };
}

export type ProxyConfigData = {
    id?: number | null;
    base_url?: string;
    query_url?: string;
    proxy_type?: string;
    service_type: string;
    auth_params?: ProxyConfigAuthData;
    other_params?: string;
    delay_check?: number;
    locale?: LocaleData | null;
}

export type ProxyConfigAuthData = {
    auth_key: string;
    auth_value: string;
}

export type ProxyConfigDataList = {
    list: ProxyConfigData[];
    totalCount: number;
}

export const transformJsonToProxyConfigDataList = (data: any[]): ProxyConfigData[] => {
    return data.map(item => transformJsonToProxyConfigData(item));
}

export const transformJsonToProxyConfigData = (data: any): ProxyConfigData => {
    return {
        id: data.id,
        base_url: data.base_url,
        query_url: data.query_url,
        proxy_type: data.type,
        service_type: data.type_service,
        auth_params: data.auth_params,
        other_params: data.other_params,
        delay_check: data.delay_check,
        locale: data.locale
    };
}

//Map<String, String>
export type ProxyParamsData = {
    [key: string]: string;
}

export const transformJsonToProxyParamsData = (data: any): ProxyParamsData => {
    return data;
}

export type ProxyTimeZoneData = {
    town: string;
    timezone: string;
    lat: number;
    lng: number;
}

export const transformJsonToProxyTimeZoneData = (data: any): ProxyTimeZoneData => {
    return {
        town: data.town,
        timezone: data.timezone,
        lat: data.lat,
        lng: data.lng
    };
}

export type ProxyData = {
    id: string;
    proxy: string;
    machine: string;
    config_id: number;
    timezone: ProxyTimeZoneData | null;
    params: ProxyParamsData | null;
}

export type ProxyDataList = {
    list: ProxyData[];
    totalCount: number;
}

export const transformJsonToProxyData = (data: any): ProxyData => {
    return {
        id: data.id,
        proxy: data.proxy,
        machine: data.name_machine || 'NULL',
        config_id: data.config_id,
        timezone: data.timezone ? transformJsonToProxyTimeZoneData(data.timezone) : null,
        params: data.proxy_params ? transformJsonToProxyParamsData(data.proxy_params) : null
    };
}

export const transformJsonToProxyDataList = (list: any[]): ProxyData[] => {
    return list.map(item => transformJsonToProxyData(item));
}

export const transformJsonToProxyDataListCount = (data: any): ProxyDataList => {
    return {
        list: transformJsonToProxyDataList(data.list),
        totalCount: data.total_count
    };
}

export type DiskData = {
    id?: number | null;
    name: string;
    count_machine?: number;
    count_machine_min?: number;
    count_machine_max?: number;
    start_machine?: number | null;
}

export type ServerData = {
    id: number | null;
    name?: string | null;
    identifier?: string | null;
    ip?: string | null;
    type: string;
    disks: DiskData[];
    server_params: {
        reboot?: boolean | null;
        task_in_queue?: number | null;
        time_to_bootloop?: number | null;
        time_between_start?: number | null;
        version_bot?: number | null;
        version_script?: string | null;
        update_link?: string | null;
    }
}

export type ServerDataList = {
    list: ServerData[];
    totalCount: number;
}

export const transformJsonToServerDataList = (list: any[]): ServerData[] => {
    return list.map(item => transformJsonToServerData(item));
}

export const transformJsonToServerDataListCount = (data: any): ServerDataList => {
    return {
        list: transformJsonToServerDataList(data.list),
        totalCount: data.total_count
    };
}

export const transformJsonToServerData = (data: any): ServerData => {
    return {
        id: data.id,
        name: data.name,
        ip: data.ip,
        type: data.type,
        disks: data.disks,
        server_params: data.server_params
    };
}

export type CampaignData = {
    id?: string;
    app: AppData;
    status?: string;
    days: CampaignDayData[];
    create_date?: string;
    update_date?: string;
    user?: UserData | null;
    locale: LocaleData;
    feedbacks?: string[];
}

export type CampaignDayData = {
    id?: string;
    day: number;
    status?: string;
    tasks: CampaignTaskData[];
}

export type CampaignTaskData = {
    id?: string;
    type: string;
    count: number;
    other_params: { [key: string]: string | number };
}

export const transformJsonToCampaignDataList = (list: any[]): CampaignData[] => {
    return list.map(item => transformJsonToCampaignData(item));
}

export const transformJsonToCampaignData = (data: any): CampaignData => {
    return {
        id: data.id,
        app: transformJsonToAppData(data.app),
        status: data.status,
        days: transformJsonToCampaignDayDataList(data.days),
        create_date: data.create_date,
        update_date: data.update_date,
        locale: transformJsonToLocaleData(data.locale),
        user: data.user == null ? null : transformJsonToUserData(data.user)
    };
}

export const transformJsonToCampaignDayDataList = (list: any[]): CampaignDayData[] => {
    return list.map(item => transformJsonToCampaignDayData(item));
}

export const transformJsonToCampaignDayData = (data: any): CampaignDayData => {
    return {
        id: data.id,
        day: data.day,
        status: data.status,
        tasks: transformJsonToCampaignTaskDataList(data.tasks)
    };
}

export const transformJsonToCampaignTaskDataList = (list: any[]): CampaignTaskData[] => {
    return list.map(item => transformJsonToCampaignTaskData(item));
}

export const transformJsonToCampaignTaskData = (data: any): CampaignTaskData => {
    return {
        id: data.id,
        type: data.type,
        count: data.count,
        other_params: data.other_params
    };
}

export type WorkingTask = {
    id: string;
    status: string;
    time_update: string;
    app: AppData;
    task: CampaignTaskData;
}

export type WorkingTaskDataList = {
    list: WorkingTask[];
    totalCount: number;
}

export const transformJsonToWorkingTaskDataList = (list: any[]): WorkingTask[] => {
    return list.map(item => transformJsonToWorkingTask(item));
}

export const transformJsonToWorkingTask = (data: any): WorkingTask => {
    return {
        id: data.id,
        status: data.status,
        time_update: data.time_update,
        app: transformJsonToAppData(data.app_dto),
        task: transformJsonToCampaignTaskData(data.task),
    }
}

export type SmsServiceData = {
    id: number;
    type: string;
    api_host: string;
    api_key: string;
    balance: number;
    countries: SmsCountryData[];
}

export const transformJsonToSmsService = (data: any): SmsServiceData => {
    return {
        id: data.id,
        type: data.type,
        api_host: data.api_host,
        api_key: data.api_key,
        balance: data.balance,
        countries: data.countries ? transformJsonToSmsCountryList(data.countries) : []
    };
}

export const transformJsonToSmsServiceList = (list: any[]): SmsServiceData[] => {
    return list.map(item => transformJsonToSmsService(item));
}

export type SmsCountryData = {
    id: number;
    service_id: number;
    country_code: string;
    marker?: string;
    locale: LocaleData;
    is_active: boolean | false;
}

export const transformJsonToSmsCountry = (data: any): SmsCountryData => {
    return {
        id: data.id,
        service_id: data.service_id,
        country_code: data.country_code,
        marker: data.marker,
        locale: transformJsonToLocaleData(data.locale),
        is_active: data.active
    };
}

export const transformJsonToSmsCountryList = (list: any[]): SmsCountryData[] => {
    return list.map(item => transformJsonToSmsCountry(item));
}

export type SmsCode = {
    code: number;
    machine_id: string | null;
    created_at: string;
}

export const transformJsonToSmsCode = (data: any): SmsCode => {
    return {
        code: data.code,
        machine_id: data.machine_id,
        created_at: data.created_at,
    };
}

export const transformJsonToSmsCodeList = (list: any[]): SmsCode[] => {
    return list.map(item => transformJsonToSmsCode(item));
}

export type SmsData = {
    id: number;
    phone: string;
    codes: SmsCode[];
    status: string;
    country: SmsCountryData;
    time_create: string;
    time_update: string;
}

export type SmsDataList = {
    list: SmsData[];
    totalCount: number;
}

export const transformJsonToSmsData = (data: any): SmsData => {
    return {
        id: data.id,
        phone: data.phone,
        codes: transformJsonToSmsCodeList(data.codes),
        status: data.status,
        country: transformJsonToSmsCountry(data.country),
        time_create: data.time_create,
        time_update: data.time_update
    };
}

export const transformJsonToSmsDataList = (list: any[]): SmsData[] => {
    return list.map(item => transformJsonToSmsData(item));
}

export const transformJsonToSmsDataListCount = (data: any): SmsDataList => {
    return {
        list: transformJsonToSmsDataList(data.list),
        totalCount: data.total_count
    };
}

export type NotificationData = {
    id: string;
    title: string;
    body: string;
    time: string;
    status: string;
}

export const transformJsonToNotificationDataList = (list: any[]): NotificationData[] => {
    return list.map(item => transformJsonToNotificationData(item));
}

export const transformJsonToNotificationData = (data: any): NotificationData => {
    return {
        id: data.id,
        title: data.title,
        body: data.body,
        time: data.time,
        status: data.status
    };
}

type ErrorResponseBody = {
    status: string,
    error_message: string
}

type ResponseBody = {
    status: string,
    data: any
}

const transformJsonToErrorResponseBody = (data: any): ErrorResponseBody => {
    return {
        status: data.status,
        error_message: data.error_message
    };
}

const transformJsonToResponseBody = (data: any): ResponseBody => {
    return {
        status: data.status,
        data: data.data
    };
}

const transformJsonToErrorResponseBodyList = (list: any[]): ErrorResponseBody[] => {
    return list ? list.map(item => transformJsonToErrorResponseBody(item)) : [];
}

const transformJsonToResponsesBodyList = (list: any[]): ResponseBody[] => {
    return list ? list.map(item => transformJsonToResponseBody(item)) : [];
}

export type FaqData = {
    host: string;
    method: string;
    type: string;
    description: string;
    requestBody: object;
    responsesBody: ResponseBody[];
    errorsBody?: ErrorResponseBody[];
}

export const transformJsonToFaqDataList = (list: any[]): FaqData[] => {
    return list.map(item => transformJsonToFaqData(item));
}

export const transformJsonToFaqData = (data: any): FaqData => {
    return {
        host: data.host,
        method: data.method,
        type: data.type,
        description: data.description,
        requestBody: data.request_body,
        responsesBody: transformJsonToResponsesBodyList(data.response_body_success),
        errorsBody: transformJsonToErrorResponseBodyList(data.response_body_error)
    };
}
