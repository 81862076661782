import React, {useEffect, useState} from "react";
import {useModal} from "../../hooks/use-modal-store";
import {Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle} from "../shared/dialog";
import {Button} from "../shared/button";
import {useLanguage} from "../../contexts/LanguageContext";
import {Separator} from "../shared/separator";
import InputChips from "../shared/input-chips";
import {AlertCircle, ImportIcon, Info} from "lucide-react";

export const AddFeedbackModal = () => {
    const {isOpen, onClose, type, callback, data} = useModal();
    const isModalOpen = isOpen && type === "add-feedback";
    const {translate} = useLanguage();

    // Количество требуемых фидбеков
    const [requireFeedbacks, setRequireFeedbacks] = useState<number>(0);

    // Состояние формы с фидбеками
    const [form, setForm] = useState<{ feedbacks: string[] }>({ feedbacks: [] });

    // Импортированные фидбеки
    const [importedKeywords, setImportedKeywords] = useState<string[]>([]);

    // Ошибки
    const [error, setError] = useState<Record<string, { value: string }>>({});

    // Загрузка данных при открытии модалки
    useEffect(() => {
        if (isModalOpen) {
            setRequireFeedbacks(data.count || 0);
            setForm({ feedbacks: data.keywords || [] });
        }
    }, [isModalOpen, data.count, data.keywords]);

    // Проверка и подтверждение фидбеков
    const handlerAddFeedbacks = () => {
        setError({});
        let hasError = false;

        if (form.feedbacks.length === 0) {
            setError(prev => ({...prev, feedbacks: {value: 'keywords.modal.one_keyword_is_required'}}));
            hasError = true;
        }

        // Проверка на соответствие количества фидбеков требуемому
        if (form.feedbacks.length !== requireFeedbacks) {
            setError(prev => ({...prev, feedbacks: {value: 'keywords.modal.feedback_count_mismatch'}}));
            hasError = true;
        }

        if (hasError) {
            return;
        }

        // Передача данных обратно в основную форму
        callback?.onConfirm?.(form.feedbacks);
        form.feedbacks = [];
        onClose();
    };

    // Импорт фидбеков из файла
    const handleImportFromFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        setError({});
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const text = e.target?.result;
                if (typeof text === 'string') {
                    let importedKeywords: string[] = text.split('\n').map(keyword => keyword.trim()).filter(Boolean);

                    if (!file.name.endsWith('.txt')) {
                        setError(prev => ({...prev, file: {value: 'keywords.modal.file_must_be_txt'}}));
                    } else {
                        setImportedKeywords(importedKeywords);
                        setForm(prev => ({ ...prev, feedbacks: [...prev.feedbacks, ...importedKeywords].slice(0, requireFeedbacks) }));
                    }
                }
            };
            reader.readAsText(file);
        }
    };

    // Закрытие модалки
    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog open={isModalOpen} onOpenChange={handleClose}>
            <DialogContent className="bg-white text-black p-0 min-w-[800px]">
                <DialogHeader className="pt-8 px-6">
                    <DialogTitle className="text-xl text-center font-bold text-gray-dark dark:text-white">
                        {translate('keywords.modal.title')}
                    </DialogTitle>
                    <Separator/>
                </DialogHeader>
                <div className="max-h-144 p-6">
                    <div className="flex flex-col gap-4">
                        <span className={"text-gray-dark dark:text-white text-sm"}>{translate('keywords.modal.description')}</span>

                        {/* Поле для ввода фидбеков */}
                        <InputChips
                            items={form.feedbacks}
                            importItems={importedKeywords}
                            maxItems={requireFeedbacks}
                            duplicate={true}
                            placeholder={translate('keywords.modal.placeholder-feedback')}
                            onChange={(value) => setForm(prev => ({...prev, feedbacks: value}))}
                            className={"min-h-[200px]"}
                        />

                        <div className={"flex flex-row justify-between"}>
                            {/* Кнопка импорта */}
                            <div>
                                <Button
                                    onClick={() => document.getElementById('fileInput')?.click()}
                                    className={"bg-blue/50 hover:bg-blue/80 dark:bg-blue/30 dark:hover:bg-blue/50"}>
                                    <div className={"flex flex-row gap-2 w-full items-center justify-start"}>
                                        <ImportIcon size={16}/>
                                        <span className={"text-xs"}>{translate('keywords.modal.import')}</span>
                                    </div>
                                </Button>
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept=".txt"
                                    onChange={handleImportFromFile}
                                    className="hidden"
                                />
                            </div>

                            {/* Счетчик добавленных фидбеков */}
                            <div className={"text-gray-dark dark:text-white flex flex-row gap-1 justify-center items-center"}>
                                <span className={form.feedbacks.length === requireFeedbacks ? "text-green" : "text-red"}>
                                    {form.feedbacks.length}
                                </span>
                                <span>/</span>
                                <span>{requireFeedbacks}</span>
                            </div>
                        </div>

                        {/* Сообщения с информацией */}
                        <div className={"flex flex-col items-start"}>
                            <div className={"flex flex-row gap-1 justify-center items-center"}>
                                <Info size={12} className={"text-gray"}/>
                                <span className={"text-xs text-gray"}>{translate('keywords.modal.add-feedback-1')}</span>
                            </div>
                            <div className={"flex flex-row gap-1 justify-center items-center"}>
                                <Info size={12} className={"text-red"}/>
                                <span className={"text-xs text-red"}>{translate('keywords.modal.add-feedback-2')}</span>
                            </div>

                            {/* Вывод ошибок */}
                            {Object.keys(error).length > 0 && (
                                <div className={"flex w-full flex-col gap-2 mt-2 justify-center"}>
                                    <Separator/>
                                    {Object.values(error).map((value, index) => (
                                        <div key={"errorDiv_" + index} className={"flex flex-row gap-2 text-red items-center"}>
                                            <AlertCircle size={14}/>
                                            <span key={`error_${index}`} className={"flex text-xs"}>{translate(value.value)}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {/* Кнопки управления */}
                <DialogFooter className="bg-gray-100 p-4">
                    <Button className={"bg-gray/50 hover:bg-gray/90"} onClick={handleClose}>
                        {translate('def.cancel')}
                    </Button>
                    <Button onClick={handlerAddFeedbacks}>
                        {translate('def.add')}
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};
