import { create } from "zustand";
import {AppData, CampaignDayData, LocaleData, SmsCountryData, SmsServiceData, WorkingTask} from "../lib/transform-json";

export type ModalType =
    "create-server" |
    "confirm-modal" |
    "delete-server" |
    "user-profile" |
    "active-machines" |
    "change-server" |
    "add-app" |
    "edit-app-locale" |
    "add-server" |
    "delete-app" |
    "locale-add" |
    "add-keywords" |
    "add-feedback" |
    "import-accounts" |
    "export-accounts" |
    "add-proxy-config" |
    "sms-country-add" |
    "sms-service-add" |
    "detail-day"|
    "tasks-modal";

type ConfirmDataModal = {
    title: string;
    description: string | "";
    button_close: string | "Close";
    button_confirm: string | "OK";
}

type ModalData = {
    serverId?: number;
    apiUrl?: string;
    dataServer?: any;
    appData?: AppData;
    localeData?: LocaleData;
    dayData?: CampaignDayData;
    smsCountryData?: SmsCountryData;
    smsServiceData?: SmsServiceData;
    tasksData?: WorkingTask[];
    confirmData?: ConfirmDataModal;
    success?: boolean;
    count?: number;
    keywords?: string[];
    query?: Record<string, any>;
}

type ModalCallback = {
    onConfirm?: (keywords?: string[]) => void;
    onAddedApp?: (appData: AppData) => void;
}

interface ModalStore {
    type: ModalType | null;
    data: ModalData;
    isOpen: boolean;
    callback?: ModalCallback
    onOpen: (type: ModalType, data?: ModalData, callback?: ModalCallback) => void;
    onClose: () => void;
}

export const useModal = create<ModalStore>((set) => ({
    type: null,
    data: {},
    isOpen: false,
    onOpen: (type, data = {}, callback = { onConfirm: () => {} }) => set({ isOpen: true, type, data, callback }),
    onClose: () => set({ type: null, isOpen: false })
}));
