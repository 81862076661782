import React, {useEffect, useState} from "react";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "../../components/shared/select";
import {ActionTooltip} from "../../components/shared/tooltip";
import {RefreshCwIcon} from "lucide-react";
import {Card} from "../../components/shared/card";
import {CampaignData, transformJsonToCampaignDataList} from "../../lib/transform-json";
import useAxiosPrivate from "../../hooks/use-axios-private";
import {Avatar, AvatarFallback, AvatarImage} from "../../components/shared/avatar";
import {useLanguage} from "../../contexts/LanguageContext";
import {ChartData, LineChart, LineData} from "../../components/shared/ag-chart";
import {getDateByLocale} from "../../lib/utils";
import {useAuth} from "../../contexts/AuthContext";

type InstallMonitorProps = {
    onChangeCampaign: (campaign: CampaignData) => void
}

export const InstallMonitor: React.FC<InstallMonitorProps> = ({onChangeCampaign}) => {

    const loadingDataInit = {
        init: false,
        refresh: false
    }
    const axiosPrivate = useAxiosPrivate();
    const {translate, language} = useLanguage();
    const {user} = useAuth();

    const [loading, setLoading] = useState(loadingDataInit);

    const chartTypes = ["ALL", "CURRENT_DAY"];

    const [campaigns, setCampaigns] = useState<CampaignData[]>([]);
    const [campaign, setCampaign] = useState<CampaignData | null>(null);
    const [chartType, setChartType] = useState<string>(chartTypes[0]);

    const [monitoringData, setMonitoringData] = useState<ChartData | null>(null);
    const [lastUpdateTime, setLastUpdateTime] = useState<string | null>(null);

    const loadCampaigns = () => {
        axiosPrivate.get(`/campaigns/list${user?.roles.some(r => r === 'ADMIN') ? '/all' : ''}?status=IN_PROGRESS&search=IN_PROGRESS`).then((response) => {
            if (response.status === 200 && response.data.data) {
                let campaignData = transformJsonToCampaignDataList(response.data.data);
                setCampaigns(campaignData);
            }
            setLoading({
                ...loading,
                init: false
            });
        }).catch((error) => {
            console.log(error);
            setLoading({
                ...loading,
                init: false
            });
        });
    }

    const handleRefresh = () => {
        setLoading({
            ...loading,
            refresh: true
        });

        //TODO request refresh data if error show toast

        setTimeout(() => {
            setLoading({
                ...loading,
                refresh: false
            });
        }, 2500);
    }

    const loadMonitoring = () => {

        setMonitoringData(null);

        setLoading(
            {
                ...loading,
                refresh: true
            }
        );

        axiosPrivate.post(`/statistic/campaign`, {
            cuid: campaign?.id,
            type: chartType,
        }).then((response) => {
            if (response.status === 200 && response.data.data) {

                let responseDataCategories = response.data.data.categories;
                let responseDataSeries = response.data.data.series;

                const keyMap = new Map<string, string>();

                const generateKey = (originalKey: string): string => {
                    // Если ключ уже существует, возвращаем его
                    if (keyMap.has(originalKey)) {
                        return keyMap.get(originalKey)!;
                    }

                    // Если ключа нет, создаём новый
                    let counter = 1;
                    let uniqueKey = `key_${counter}`;

                    while (Array.from(keyMap.values()).includes(uniqueKey)) {
                        counter++;
                        uniqueKey = `key_${counter}`;
                    }

                    // Сохраняем новый ключ в Map
                    keyMap.set(originalKey, uniqueKey);

                    return uniqueKey;
                };

                let result: ChartData = {
                    data: [],
                    series: []
                }

                responseDataCategories.forEach((time: number) => {
                    let existingItem = result.data.find((item: LineData) => item.dateTime === time);

                    if (!existingItem) {
                        result.data.push({dateTime: time});
                        existingItem = result.data[result.data.length - 1]; // Получаем только что добавленный элемент
                    }

                    Object.keys(responseDataSeries).forEach((key) => {
                        const uniqueKey = generateKey(key);

                        // Добавляем данные
                        existingItem!![uniqueKey] =
                            responseDataSeries[key].find((v: { time_check: number }) => v.time_check === time)?.value ?? 0;
                    });
                });

                result.data.forEach((item) => {
                    // Суммируем все числовые значения
                    item.total = Object.entries(item)
                        .filter(([key]) => key !== "dateTime" && key !== "total") // Исключаем `dateTime` и `total`
                        .reduce((sum, [_, value]) => sum + (value as number), 0);

                    //Добавляем серии
                    Object.keys(item).forEach((key) => {
                        if (key !== "dateTime") {
                            let existingSeries = result.series.find((series) => series.yKey === key);

                            if (!existingSeries) {

                                let originalName = Array.from(keyMap.entries()).find(([_, value]) => value === key)?.[0] || key;

                                result.series.push({
                                    type: 'line',
                                    xKey: 'dateTime',
                                    yKey: key,
                                    yName: key === 'total' ? 'Total' : originalName.split('$$').length > 1 ? originalName.split('$$')[0] + ' - ' + originalName.split('$$')[1] : originalName,
                                    interpolation: {type: 'smooth'},
                                    visible: key === 'total',
                                });
                            }
                        }
                    });
                });

                // Устанавливаем данные
                setLastUpdateTime(response.data.data.time_update);
                setMonitoringData(result);
            }
            setLoading(
                {
                    ...loading,
                    refresh: false
                }
            );

        }).catch((error) => {
            console.log(error);
            setLoading(
                {
                    ...loading,
                    refresh: false
                }
            );
        });
    }

    useEffect(() => {
        if (campaign) {
            loadMonitoring();
        }
    }, [campaign, chartType]);

    useEffect(() => {
        onChangeCampaign && campaign && onChangeCampaign(campaign);
    }, [campaign]);

    useEffect(() => {
        loadCampaigns();

        return () => {

        }
    }, []);

    return (
        <Card>
            <div className={"grid grid-cols-3 w-full items-center"}>
                <div className={"flex justify-start"}>
                    <span className={"font-bold text-xl text-gray-dark dark:text-white"}>{translate('dashboard.statistic.title')}</span>
                </div>

                <div className={"flex flex-row justify-center items-center gap-4"}>
                    <div className={"flex flex-col gap-1"}>
                        <div className={"flex flex-row items-center gap-2"}>
                            <div className={"w-72"}>
                                <Select
                                    disabled={loading.init || loading.refresh}
                                    onValueChange={(value) => setCampaign(campaigns.find(c => c.id === value) || null)}
                                >
                                    <SelectTrigger
                                        className={`h-16 bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none`}>
                                        <SelectValue placeholder={translate('campaigns.my.select-campaign')}/>
                                    </SelectTrigger>

                                    <SelectContent className={"flex flex-col"}>
                                        <div className={"h-fit max-h-60 overflow-scroll"}>
                                            {campaigns.length === 0 && (
                                                <span className={"flex text-xs text-gray-dark dark:text-white font-bold items-center justify-center"}>
                                                    {translate('campaigns.my.no-found-campaigns')}
                                                </span>
                                            )}
                                            {campaigns.map((campaign) => (
                                                <SelectItem
                                                    key={campaign.id}
                                                    value={campaign.id!!}
                                                    className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                                >
                                                    <div className={"flex flex-row gap-2"}>
                                                        <div className="relative">
                                                            <Avatar className="h-12 w-12 transition-all">
                                                                <AvatarFallback delayMs={500}>I</AvatarFallback>
                                                                <AvatarImage
                                                                    src={campaign.app.details[`${campaign.locale.country}_${campaign.locale.language}`].image_src}
                                                                />
                                                            </Avatar>

                                                            <img
                                                                className="absolute bottom-0 right-0 h-4 w-6 rounded-md overflow-hidden shadow-md"
                                                                alt={campaign.locale.country.toUpperCase()}
                                                                src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${campaign.locale.country.toUpperCase()}.svg`}
                                                            />
                                                        </div>
                                                        <div className={"flex flex-col items-start justify-center"}>
                                                                <span
                                                                    className={"max-w-[180px] whitespace-nowrap truncate"}>
                                                                    {campaign.app.details[campaign.locale.country + '_' + campaign.locale.language].name}
                                                                </span>
                                                            <span
                                                                className={"text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                    {campaign.app.details[campaign.locale.country + '_' + campaign.locale.language] ?
                                                                        campaign.app.details[campaign.locale.country + '_' + campaign.locale.language].publisher :
                                                                        campaign.app.details['us_en'].publisher}
                                                                </span>
                                                            {campaign.user?.username !== user?.username && (
                                                                <span
                                                                    className={"text-xs text-gray-dark/50 dark:text-white/70 max-w-[200px] whitespace-nowrap truncate"}>
                                                                        {campaign.user?.username}
                                                                    </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </SelectItem>
                                            ))}
                                        </div>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className={"w-52"}>
                        <Select
                            disabled={loading.init || loading.refresh}
                            onValueChange={(value) => setChartType(value)}
                            defaultValue={chartTypes[0]}
                        >
                            <SelectTrigger
                                className="bg-green/50 border-0 focus:ring-0 text-gray-dark font-bold dark:text-white ring-offset-0 focus:ring-offset-0 capitalize outline-none h-12">
                                <SelectValue placeholder="Chart type"/>
                            </SelectTrigger>

                            <SelectContent>
                                {Object.values(chartTypes).map((type) => (
                                    <SelectItem
                                        key={type}
                                        value={type}
                                        className="cursor-pointer font-bold capitalize hover:bg-green/40 rounded-md text-xs"
                                    >
                                        {type}
                                    </SelectItem>
                                ))}
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div className={"flex flex-row items-center gap-1 justify-end"}>
                    {loading.init || loading.refresh ?
                        <div className={"w-20 h-2 bg-gray/50 rounded-full animate-[pulse_1.5s_infinite]"}/> :
                        <ActionTooltip label={"Last update time"}>
                            <span
                                className={"text-xs text-gray/50"}>{getDateByLocale(lastUpdateTime, language) === '' ? translate('def.no-data') : getDateByLocale(lastUpdateTime, language)}</span>
                        </ActionTooltip>
                    }
                    <ActionTooltip label={"Refresh"}>
                        <div
                            className={"p-2 hover:bg-green/20 rounded-md text-gray-dark dark:text-white relative flex items-center transition-all"}
                            onClick={handleRefresh}
                        >
                            <RefreshCwIcon
                                size={20}
                                className={`text-gray-dark dark:text-white cursor-pointer ${loading.init || loading.refresh ? 'animate-[spin_1.5s_infinite]' : ''}`}/>
                        </div>
                    </ActionTooltip>
                </div>
            </div>

            <LineChart chartData={monitoringData} loading={loading.refresh} typeDate={chartType === 'ALL' ? 'withoutHour' : 'withHour'}/>
        </Card>
    )
}
